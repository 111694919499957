.about-container {
  padding: 160px 0 200px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.handsImage {
  width: 50%;
  margin: auto;
  transform: translateY(-180px);
}

.row {
  width: 100%;
}
.disignation {
  font-size: 12px;
}
.employee_name {
  font-size: 15px;
}

.main_container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.main_container .col-lg-4 {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .home-container {
    height: auto;
  }
  .about-container {
    padding: 160px 0 115px 0;
  }
  .handsImage {
    transform: translateY(-100px);
  }
  .font_40x {
    font-size: 25px;
  }
  .font_30x {
    font-size: 15px;
    line-height: 1.2;
  }

  .handsImage {
    width: 63%;
  }
}
