.container {
  /* Your container styles */
}

.header {
  /* Background image styles */
  height: 87px;
  width: 100%;
  background-image: url("../../../assets/meme/bg.png");
  background-size: cover;
  margin-top: 70px;
}

.main-heading-container {
  display: flex;
  justify-content: center;
  margin: 150px auto 100px auto;
  align-items: center;
  gap: 10px;
}
.main-heading {
  text-align: center;
  font-weight: bold;
  font-size: 70px;
}
.category-section,
.project-section {
  /* Your section styles */
  padding: 20px;
}

.card-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.card-container .card {
  background: none;
  border: none;
  min-width: 200px;
  max-width: 250px;
  height: auto;
  min-height: 100px;
}

.card-container .card a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: black;
  text-align: center;
}
.project-container {
  /* Flex container styles */
  max-width: 1000px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.project-card {
  /* Card styles */
  max-width: 410px;
  padding: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  /* Adjust width and margin as needed for responsiveness */
}
.hoverImage {
  transition: all 0.2s linear;
}
.hoverImage:hover {
  transform: scale(1.05);
}
.card img {
  height: 150px;
  transition: all 0.2s linear;
}
.card img:hover {
  transform: scale(1.05);
}
.project-card img {
  /* Image styles */
  height: 200px;
  cursor: pointer;
  transition: all 0.2s linear;
}
.project-card img:hover {
  transform: scale(1.05);
}
.card h2,
.project-card h2 {
  /* Heading styles */
}

.project-card p {
  /* Description styles */
  margin-bottom: 20px;
}

.learn-more-button {
  /* Button styles */
  display: block;
  width: 100%;
  background-color: #f8c229;
  color: black;
  text-align: center;
  text-decoration: none;
  padding: 10px;
  border: none;
}
.learn-more-button:hover {
  background-color: #ffd000;
  color: black;
}
@media (max-width: 900px) {
  .main-heading {
    font-size: 62px;
  }
  .main-heading-container {
    flex-direction: column;
    gap: 15px;
  }
  .card-container {
    gap: 80px;
  }
}
