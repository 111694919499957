.home-containers {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  background-color: var(--body_background);
  padding-bottom: 150px;
}

.home-container-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  flex-direction: column;
  overflow: hidden;
}

.center-Image {
  height: 136px;
  /* margin-top: -130px; */
  margin-top: 50px;
}

.section-heading {
  color: white;
  font-family: AvenirMedium, serif;
  font-size: 25px;
  text-align: center;
  margin-top: -40px;
}

.section-helper-text {
  font-family: AvenirMedium, serif;
  font-size: 15px;
  color: #d9d9d9;
  padding: 10px 20px 25px;
  text-align: center;
  /* margin-top: -30px; */
}

.section-usp {
  color: white;
  font-family: AvenirMedium, serif;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0em;
  padding: 20px;

  padding-top: 120px;
  padding-bottom: 70px;
}

.font-big {
  color: white;
  font-size: 45px;
}

.big_trade_font {
  font-size: 120px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 20px;
}

.cut_button {
  width: 200px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  cursor: pointer;
  text-align: center;
}

.login-button {
  border-bottom: 50px solid #11be6a;
  border-left: 25px solid transparent;
  margin-right: -20px;
  margin-bottom: -42px;
}

.login-button:hover {
  border-bottom-color: #0ea55a;
  border-left-color: transparent;
}

.launch-app-button {
  border-top: 50px solid #006dff;
  border-right: 25px solid transparent;
  margin-bottom: -42px;
  margin-right: -20px;
}
.launch-app-button:hover {
  border-top: 50px solid #00387e;
  border-right: 25px solid transparent;
  margin-bottom: -42px;
  margin-right: -20px;
}
.desc {
  margin-top: 20px; /* Adjust as needed */
  text-align: center;
  font-size: 14px; /* Adjust as needed */
  display: block;
}

.abc {
  flex: 1;
  margin-bottom: 20px; /* Add spacing between rows */
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.4s ease-in-out;
}

/* .abc:hover {
  transform: scale(1.15);
} */

.launch-app-button-text {
  margin-bottom: 48px;
}

.login-button_text {
  margin-top: 48px;
}

.home-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 70px 10px;
  width: 100%;
  max-width: 1280px;
}
.home-main-container-left {
  flex: 1;
}
.signInHover:hover {
  border: 1px solid #11be6a;
  background: none !important;
  color: white !important;
}
.wibsBuyHover:hover {
  opacity: 0.8;
}
.signOutHover:hover {
  background: #11be6a;
  color: black !important;
}
.home-main-container-left h1 {
  font-size: 78px;
  line-height: 98px;

  width: 85%;
  margin-bottom: 50px;
}
.home-main-container-left h3 {
  font-size: 22px;
}
.home-main-container-right {
  flex: 1;
}
.home-main-container-right p {
  font-size: 36px;

  font-style: italic;
}
.home-main-container-right-feature {
  display: flex;
  justify-content: space-between;
  margin: 70px 0;
  gap: 10px;
  align-items: center;
}
.home-main-container-right-feature > div {
  width: 50%;
}
.home-main-container-right-feature .daCrazyContainer {
  display: flex;
  justify-content: flex-end;
}
.home-main-container-right-feature > div > img {
  width: 80%;
}
.home-main-container-right-feature > div img {
  transition: all 0.3s linear;
  cursor: pointer;
}
.home-main-container-right-feature > div img:hover {
  transform: scale(1.05);
}

.home-main-container-right-feature p {
  font-size: 36px;

  font-style: italic;
}

.home-main-container-right-new p {
  font-size: 28px;
}
/* Responsive design for tablet and mobile */
@media (max-width: 768px) {
  .home-main-container {
    flex-direction: column;
    gap: 50px;
    text-align: center;
    justify-content: center;
  }
  .home-main-container-left h1 {
    width: 100%;
  }
  .home-main-container-right {
    width: 100%;
  }
  .home-main-container-right-feature {
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
  }
  .home-main-container-right-feature > div {
    width: 100%;
  }
  .home-main-container-right-feature .feature-of-month {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-main-container-right-feature .daCrazyContainer {
    justify-content: center;
  }
}

.td-none {
  text-decoration: none !important;
}

@media (max-width: 767px) {
  .desc2 {
    display: none !important;
  }
  .abc {
    margin-bottom: 50px;
  }
  .section-heading {
    color: white;
    font-family: AvenirMedium, serif;
    font-size: 25px;
    text-align: center;
    margin-top: 0px;
  }
}

/* .desc {
  opacity: 1;
  color: white;
} */

/* .abc:hover .desc {
  opacity: 1;
  color: white;
} */

.desc2 {
  display: block;
  opacity: 0;
  font-size: 12px;

  margin-top: 5px;
  padding: 5px;
}

.abc:hover .desc2 {
  opacity: 1;
}
.abc img {
  transition: all 0.3s linear;
}
.abc:hover img {
  transform: scale(1.1);
  transition: all 0.3s linear;
}
.shopImage {
  transform: scale(1.2);
  transition: all 0.3s linear;
}
.abc:hover .shopImage {
  transform: scale(1.3);
  transition: all 0.3s linear;
}
.enlarge:hover {
  transform: scale(1.1);
  transition: transform 330ms ease-in-out;
}

/* Add the below CSS to remove scaling when not on hover */
.enlarge {
  transition: transform 330ms ease-in-out;
}

.abc {
  text-align: center;
}

/* .desc {
  color: white;
  margin-top: 5px;
} */
