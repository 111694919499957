.font_42x {
  font-size: 42px;
}

.pay-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pay-label {
  margin-bottom: 10px;
}

.radio-group {
  display: flex;
  flex-direction: row !important;
  align-items: flex-start;
}

.img-contain {
  width: 100%;
  display: flex;
  justify-content: center;
}

.img-pile {
  padding-block: 50px;
}
/* .radio-button {
    margin-left: 10px;
}

.radio-button {
    border: 2px solid transparent;
  } */

.radio-button.selected {
  border-color: #007bff; /* Set the desired color for the outline */
}

.label-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pay-image {
  /* width: 120px; */
  padding: 5px;
  height: 70px;
}
.select-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.select-image {
  padding: 5px;
  height: 40px;
  text-align: left;
}

.pay-description {
  padding-top: 30px;
  font-size: 15px;
  text-align: center;
}

.selected-opt {
  border: 2px solid #0078e5; /* Adjust the color as needed */
  border-radius: 4px;
  padding: 5px;
  height: 70px;
  /* width: 120px; */
}

.MuiInputBase-root.MuiOutlinedInput-root {
  color: var(--body_color) !important;
  border-radius: 2px !important;
  border: none;
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--border-color) !important;
}

.select-input {
  min-width: 330px !important;
}

.drop-image {
  width: 40px;
  margin-right: 10px;
}

.drop2-image {
  width: 35px;
  margin-right: 10px;
}

.hive-img {
  width: 105px;
  height: 75px;
}

.hive-img:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.text-color {
  color: #333333;
}

@media (max-width: 767px) {
  .pay-image {
    width: 60px;
  }

  .select-image {
    margin-right: 10px;
    height: 20px;
  }

  .pay-box {
    padding-inline: 10px;
    width: 100vw;
  }

  .close-button-pay {
    top: -13%;
    left: 96%;
    font-size: 1.5rem;
  }

  .pay-text-box {
    padding-left: 1rem;
    padding-bottom: 0;
    padding-top: 1rem;
  }

  .pay-button-btn {
    font-size: 15px;
    width: 25%;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    border: 0;
    border-radius: 2px;
  }

  .img-pile {
    padding-block: 30px;
  }

  .img-pile img {
    width: 190px;
  }
}
