.toolbar-btn {
  background: transparent;
  color: var(--dark_text);
  width: fit-content;
  font-size: 15px;
}

.toolbar-text {
  font-size: 20px;
  align-self: center;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none;
}

.rbc-off-range-bg {
  background: none;
  color: black;
}

.rbc-today {
  background: none;
}

.rbc-month-view,
.rbc-header + .rbc-header,
.rbc-header,
.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row {
  border-color: var(--border-color) !important;
}
