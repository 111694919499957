.discount_hero_root {
  position: relative;
}

.discount_hero_star_overlay {
  display: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 410px;
  z-index: 1;
}

.discount_hero_star_overlay img {
  width: 100%;
  z-index: 1;
}
.discount_hero_text_container {
  position: absolute;
  padding: 50px;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.discount_hero_text {
  max-width: 600px;
  margin: auto;
  font-size: 26px;
  color: #000;
  text-align: center;
}
.lucky_day_text {
  display: block;
  font-weight: bold;
  font-size: 40px;
  text-transform: uppercase;
}
.percentOff {
  display: block;
  font-weight: bolder;
  font-size: 102px;
  text-transform: uppercase;
  margin: -20px;
}

.when_you_text {
  font-size: 38px;
}

.tab-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.tab-item img {
  width: 50px;
  height: 50px;
}

.tab-text {
  text-align: center;
}

.top {
  font-weight: bold;
}

.bottom {
  font-size: 0.8em;
}

.tabs-container {
  display: flex;
  flex-wrap: wrap; /* Add flex-wrap property */
  max-width: 1000px;
  margin: 50px auto 0px;
}
.tabs-container div {
  width: 170px;
  margin: auto;
}

.all-cards-styling {
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: 1000px;
  margin: auto;
  align-items: stretch;
}

@media screen and (max-width: 320px) {
  .all-cards-styling {
    grid-template-columns: 1fr; /* Display one card per row for screens with a width of 320px or smaller */
  }
  .discount_hero_star_overlay {
    height: 467px;
  }
  .percentOff {
    font-size: 58px;
    margin: -11px;
  }
  .discount_hero_text {
    font-size: 22px;
  }
  .when_you_text {
    font-size: 30px;
  }
}

/* fixing it */
/* @media screen and (min-width: 390px) { */
/* @media screen and (min-width: 390px) and (max-width: 768px) { */
@media screen and (max-width: 768px) {
  .all-cards-styling {
    grid-template-columns: 1fr;
  }
  .discount_hero_star_overlay {
    height: 467px;
  }
  .percentOff {
    font-size: 58px;
    margin: -11px;
  }
  .discount_hero_text {
    font-size: 22px;
  }
  .when_you_text {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  .all-cards-styling {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .tab-item {
    display: block;
  }
}
