:root {
  --body_background: #f4f4f6;
  --body_color: #5f5f5f;
  --disabled-btn: 0, 0, 0;
  --link_color: #2607f3;
  --table_header: #939292;
  --table-border: #e6e8ea;
  --section3-unselected: rgba(0, 0, 0, 0.15);
  --blog-border-orange: rgba(246, 96, 54, 0.2);
  --market-table-color: #fff;
  --border-color: #d9d9d9;
  --card-color: #fff;
  --dark_text: #000;
  --timer-color: #000000;
  --calendar-disabled: #e6e6e6;
  --cal-bg: #eaf6ff;
}

[data-bs-theme="dark"] {
  --body_background: #181a20;
  --body_color: #e0e0e0;
  --disabled-btn: 255, 255, 255;
  --link_color: #f307b8;
  --table_header: #aeaeae;
  --table-border: #3c3b3b;
  --section3-unselected: rgba(255, 255, 255, 0.25);
  --blog-border-orange: rgba(59, 130, 246, 0.3);
  --market-table-color: #575757;
  --border-color: #282828;
  --card-color: #282828;
  --dark_text: #fff;
  --timer-color: #2d2d2d;
  --calendar-disabled: #2c2c2c;
  --cal-bg: #263e51;
}
/* * {
  transition: all 0.2s ease-in-out;
} */

@font-face {
  font-family: AvenirLight;
  src: url(./assets/fonts/AvenirLight.ttf) format("truetype");
}

@font-face {
  font-family: AvenirRegular;
  src: url(./assets/fonts/AvenirRoman.ttf) format("truetype");
}

@font-face {
  font-family: AvenirMedium;
  src: url(./assets/fonts/AvenirMedium.ttf) format("truetype");
}

@font-face {
  font-family: AvenirHeavy;
  src: url(./assets/fonts/AvenirBlack.ttf) format("truetype");
}

@import "~antd/dist/antd.css";

html {
  font-size: 15px;
}

body {
  margin: 0;
  font-family: AvenirRegular, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body_background);
  color: var(--body_color);
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--body_color);
}

.flex-align-start {
  align-items: start;
}

div.flex-align-center {
  align-items: center;
}

div.flex-align-stretch {
  align-items: stretch;
}

div.flex-justify-center {
  justify-content: center;
}

.mb-0 {
  margin-bottom: 0;
}

.card {
  min-width: 420px;
  background-color: var(--body_background);
  border: 1px solid rgba(0, 109, 255, 0.5);
  min-height: 600px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-width: 420px;
}

.card__header {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 109, 255, 0.5);
}

.card__header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__header__inner__left {
  flex: 1 1;
}

.card__header__inner__right {
  display: flex;
  flex: 1 1 0px;
  justify-content: flex-end;
}

.card__body {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.card__footer {
  text-align: center;
  padding: 20px;
}

.card__title {
  color: var(--body_color) !important;
  font-size: 40px;
  margin-bottom: 0;
  flex: 1 1;
  line-height: 1.2;
}

.orange.card {
  border: 1px solid rgba(246, 96, 54, 0.3);
}

.tag {
  font-size: 1em;
  font-family: AvenirHeavy;
  border-radius: 10px;
  padding: 5px 10px 2px;
  background-color: var(--body_background);
  color: var(--body_color);
}

.input__field {
  border: 1px solid rgba(0, 109, 255, 0.5);
  border-radius: 5px;
  height: 56px;
  line-height: 60px;
}

.input__field input {
  text-align: right;
  font-size: 25px;
  padding-right: 30px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

iframe {
  display: none;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-direction-column {
  flex-direction: column;
}

.index_tokens img {
  width: 176px;
  height: 176px;
}

.font_25x {
  font-size: 25px;
}

.modal-backdrop {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border-radius: 5px;

  max-width: 80%;
}

.close-btn-modl {
  position: absolute;
  width: 5%;
  top: -19px;
  right: 135px;
  background: none;
  /* border: 1px solid; */
  font-size: 20px;
  cursor: pointer;
  transition: all 0.1s ease;
}
.close-btn-modl:hover {
  transform: scale(1.1);
}
.modal-body {
  /* Add your modal body styles here */
  position: relative;
  border: "1px solid red";
  height: 420px;
}

.modl-btn {
  position: absolute;
  width: 220px;
  height: 41px;
  border-radius: 0;
  transition: all 0.1s ease;
}
.modl-btn:hover {
  transform: scale(1.015);
}

.mod-green-2 {
  left: 550px;
  bottom: 10px;
}
.mod-green-1 {
  left: 100px;
  bottom: 10px;
}
.mod-blue {
  right: 100px;
  bottom: 10px;
  background: #004dcd;
}
.modals-container {
  display: flex; /* Set display to flex */
  flex-wrap: wrap;
  /* flex-direction: row; */
  justify-content: center;
  justify-items: center;
  text-align: center;
}

.modals-item {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto; /* Adjust width for web view */
}
.discountImage {
  background-image: url("assets/discountShop/discountHero.svg");
  width: 100%;
  max-width: 1920px;
  margin: auto;
  height: 500px;
  background-size: contain;
  background-repeat: no-repeat;
}

.discountCard_root {
  max-width: 400px;
  margin: auto;
  margin-bottom: 100px;
  height: 100%;
}

/*  */
.card {
  width: 300px;
  height: 400px;
  background-color: blue;
}
.card-back {
  background-color: red;
}
/*  */

@media screen and (max-width: 768px) {
  .discountCard_root {
    margin-left: auto;
    margin-right: auto;
  }
}

.discountCard_border {
  padding-bottom: 50px;
  margin-bottom: 50px;
}
.discountCard_header {
  background: #ffb300;
  color: #000;
  font-weight: bold;
  font-size: 26px;
  padding: 10px;
  text-align: center;
}
.detailed_section {
  height: 390px;
}
.detailed_text {
  font-size: 17px;
  color: #000;
  text-align: center;
}

.discountCard_mainText {
  width: 80%;
  margin: 30px auto;
  text-align: center;
  color: #000;
}
.discountCard_cryptoImg_container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.discountCard_cryptoImg {
}
.discountCard_price {
  font-size: 24px;
  font-weight: bolder;
  color: #000;
  text-align: center;
}

@media (max-width: 768px) {
  /* Adjust breakpoint for small screens */
  .modals-item {
    width: 100%; /* Stacks items on small screens */
  }
}

.modals-image {
  width: 100%;
  height: 100%;
  /* Ensure images fill their container */
}

.modals-button {
  display: block;
  background: #0155ee;
  width: 220px;
  border-radius: 0;
  height: 41px;
  margin: -10px auto 30px;
}
.modals-button:hover {
  background: #1b6cff;
}
.modals-button-blue {
  display: block;
  width: 220px;
  border-radius: 0;
  height: 41px;
  margin: 20px auto;
  background: #004dcd;
}
.modals-button-blue:hover {
  transform: scale(1.015);
}

@media (max-width: 990px) {
  .close-btn-modl {
    top: -45px;
    right: 0px;
  }
}
