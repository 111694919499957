h1 {
  margin-bottom: 0;
  font-size: 30px;
}

.card_title {
  font-size: 40px;
  color: #5f5f5f;
  line-height: 1.2;
}

.helper_text {
  color: #5f5f5f;
  font-size: 14px;
  font-family: AvenirHeavy;
}

.card_body {
  padding: 20px;
}

.swap__coin {
  padding: 23px 0 23px 0;
}

.footer {
  padding: 0 20px 20px 20px;
  color: #5f5f5f;
}

.footer.ant-btn-primary:hover,
.footer .ant-btn-primary:focus {
  background-color: #00387d;
  border-color: #00387d;
}

.footer_body {
  padding-top: 10px;
}

.disable_icon {
  opacity: 0.5;
}
