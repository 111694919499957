.logo_prod {
  transition: transform 330ms ease-in-out;
}

.logo_prod:hover {
  transform: scale(1.1);
  transition: transform 330ms ease-in-out;
}

.centerText {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card__title {
  color: var(--body_color);
  text-align: center;
}
.scan-container {
  padding: 20px;
}

.section-container {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-header {
  font-size: 30px;
  color: #ffffff;
  text-align: center;
}
.subtitle {
  text-align: center;
}
.subtext {
  font-size: 24px;
  font-weight: 500;
  color: #eaecef;
}
.button-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  max-width: 730px;
  width: 100%;
  margin: 30px auto;
}

.small-button {
  flex: 1;
  margin: 0 5px;
  font-size: 12px;
  padding: 5px 10px;
}

.section-text {
  margin-top: 10px;
  text-align: center;
}

.section-image-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .card__title {
    font-size: 45px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .subtext {
    font-size: 20px !important;
  }

  .connected-ecosystem-container {
    text-align: center;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    margin-top: 200px;
  }

  .steps-container {
    text-align: center;
    margin: 0 20px;
  }

  .section-container {
    margin: 20px 0;
  }

  .logo_prod {
    width: 100px !important;
    height: auto !important;
    margin-top: 50px !important;
  }
}
