.firstButton {
  background-color: #00b64f !important;
  border-radius: 10;
  border-color: #00b64f !important;
  width: 200px;
  height: 30px;
}

.firstButton:hover {
  background-color: #007433 !important;
  border-color: #007433 !important;
}

.secondButton {
  background-color: #0079fa !important;
  border-radius: 10;
  border-color: #0079fa !important;
  width: 200px;
  text-align: center;
  height: 30px;
}

.secondButton:hover {
  background-color: #012dac !important;
  border-color: #012dac !important;
}

.thirdButton {
  background-color: #1d860a !important;
  border-radius: 10;
  border-color: #1d860a !important;
  width: 200px;
  height: 30px;
}

.thirdButton:hover {
  background-color: #155e09 !important;
  border-color: #155e09 !important;
}

.fourthButton {
  background-color: #0259b8 !important;
  border-radius: 10;
  border-color: #0259b8 !important;
  width: 200px;
  text-align: center;
  height: 30px;
}
.wibsButton {
  background-color: #fa8d01 !important;
  border-radius: 10;
  border-color: #fa8d01 !important;
  width: 200px;
  text-align: center;
  height: 30px;
}
.daCrazyButtonDarkMode {
  background-color: #000 !important;
  border-radius: 10;
  color: #fff;
  border-color: #000 !important;
  width: 200px;
  text-align: center;
  height: 30px;
}
.daCrazyButtonLightMode {
  background-color: #fff !important;
  border-radius: 10;
  color: #000;
  border-color: #fff !important;
  width: 200px;
  text-align: center;
  height: 30px;
}
.daCrazyButtonDarkMode:hover,
.daCrazyButtonLightMode:hover {
  opacity: 0.7;
}
.wibsButton:hover {
  background-color: #d47902 !important;
  border-color: #d47902 !important;
  text-align: center;
}
.fourthButton:hover {
  background-color: #03468d !important;
  border-color: #0259b8 !important;
  text-align: center;
}

.fifthButton {
  background-color: #ffc205 !important;
  border-radius: 10;
  border-color: #ffc205 !important;
  width: 200px;
  text-align: center;
  height: 30px;
}

.fifthButton:hover {
  background-color: #e0aa05 !important;
  border-color: #e0aa05 !important;
  text-align: center;
}

.sixthButton {
  background-color: #000000 !important;
  border-radius: 10;
  border-color: #ffffff !important;
  width: 200px;
  text-align: center;
  height: 30px;
  color: white;
}

.sixthButton:hover {
  background-color: #353434 !important;
  border-color: #ffffff !important;
  text-align: center;
  color: white;
}

/* .sixthButton {
  background-color: #ffffff !important;
  border-radius: 10;
  border-color: #020202 !important;
  width: 200px;
  text-align: center;
  height: 30px;
  color:#000000;
}

.sixthButton:hover {
  background-color: #ffffff !important;
  border-radius: 10;
  border-color: #020202 !important;
  width: 200px;
  text-align: center;
  height: 30px;
  color:#000000;
} */

.photext {
  font-size: 35px;
  color: #ffc205;
}

.pholinktext {
  font-size: 14px;
  color: #ffc205;
}
.pholinktext:hover {
  color: #e0aa05;
}

.photext:hover {
  font-size: 35px;
  color: #e0aa05;
}

.cryptotext {
  font-size: 35px;
  color: #00b64f;
}

.cryptolinktext {
  font-size: 14px;
  color: #00b64f;
}
.cryptolinktext:hover {
  color: #029743;
}

.cryptotext:hover {
  font-size: 35px;
  color: #029743;
}

.in500text {
  font-size: 35px;
  color: #0079fa;
}
.in500text:hover {
  font-size: 35px;
  color: #012dac;
}
.in500linktext {
  font-size: 14px;
  color: #0079fa;
}
.in500linktext:hover {
  color: #012dac;
}

.iusdtext {
  font-size: 35px;
  color: #1d860a;
}
.iusdtext:hover {
  font-size: 35px;
  color: #155e09;
}
.iusdlinktext {
  font-size: 14px;
  color: #1d860a;
}
.iusdlinktext:hover {
  color: #155e09;
}

.inextext {
  font-size: 35px;
  color: #0259b8;
}
.inextext:hover {
  font-size: 35px;
  color: #03478f;
}
.inexlinktext {
  font-size: 14px;
  color: #0259b8;
}
.wibslinktext {
  font-size: 14px;
  color: #f58d06;
}
.daCrazylinktextDarkMode {
  font-size: 14px;
  color: #fff;
}
.daCrazylinktextLightMode {
  font-size: 14px;
  color: #000;
}
.daCrazylinktextDarkMode:hover,
.daCrazylinktextLightMode:hover {
  opacity: 0.7;
}
.wibslinktext:hover {
  font-size: 14px;
  color: #cc7503;
}
.inexlinktext:hover {
  color: #03478f;
}

/* .sorekttext {
  font-size: 35px; */
/* color: #000000; */
/* color: var(--body_color) !important; */

/* } */

/* .sorektlinktext {
  font-size: 14px;
  color: var(--body_color) !important; */
/* color: #000000; */
/* } */

.scaleup {
  transition: all 0.2s linear;
}
.scaleup:hover {
  transform: scale(1.1);
}

.in500text {
  font-size: 35px;
  color: #0079fa;
}
.in500text:hover {
  font-size: 35px;
  color: #012dac;
}
.in500linktext {
  font-size: 14px;
  color: #0079fa;
}
.in500linktext:hover {
  color: #012dac;
}

.iusdtext {
  font-size: 35px;
  color: #1d860a;
}
.iusdtext:hover {
  font-size: 35px;
  color: #155e09;
}
.iusdlinktext {
  font-size: 14px;
  color: #1d860a;
}
.iusdlinktext:hover {
  color: #155e09;
}

.show-m {
  color: #1d860a;
  font-size: 13px;
  background: none;
}
.show-m:hover {
  background: none;
  color: #155e09;
}

.inextext {
  font-size: 35px;
  color: #0259b8;
}
.inextext:hover {
  font-size: 35px;
  color: #03478f;
}
.inexlinktext {
  font-size: 14px;
  color: #0259b8;
}
.inexlinktext:hover {
  color: #03478f;
}

.sorekttext {
  font-size: 35px;
  /* color:#353434; */
  color: var(--body_color) !important;
}
.sorekttext:hover {
  font-size: 35px;
  /* color:#212121; */
  color: var(--table_header) !important;
}
.sorektlinktext {
  font-size: 14px;
  /* color:#353434 !important; */
  color: var(--body_color) !important;
}
.sorektlinktext:hover {
  /* color:#353434 !important; */
  color: var(--table_header) !important;
}

@media screen and (max-width: 768px) {
  .how-it-works {
    gap: 100px;
  }

  .how-it-works .row {
    flex-direction: column;
    gap: 100px;
  }

  .how-it-works .col-3 {
    width: 100%;
  }
}
